import * as React from "react"
import Subheading from "./elements/subheading"
import TextBlockWithBorder from "./elements/text_block_with_border"

const GridList = ({title, items}) => (
  <div className="w-full">
    {title !== null &&
      <Subheading title={title} />
    }
    <div className="grid grid-cols-3 grid-rows-5 md:gap-x-8 md:gap-0 md:grid-rows-3 lg:gap-0 md:grid-cols-6 lg:grid-cols-5">
      <TextBlockWithBorder css="border-skin-pink md:col-span-2 md:col-start-3 lg:col-start-3" 
        title={items[0].title}
        description={items[0].description} />
      <TextBlockWithBorder css="border-skin-red col-start-2 row-start-2 lg:col-start-2 lg:row-start-2" 
        title={items[1].title}
        description={items[1].description} />
      <TextBlockWithBorder css="border-skin-red row-start-3 md:row-start-2 md:col-span-2 md:col-start-4 lg:col-start-4 lg:row-start-2" 
        title={items[2].title}
        description={items[2].description} />
      <TextBlockWithBorder css="border-skin-orange col-start-2 row-start-4 md:row-start-3 md:col-start-1 lg:col-start-1 lg:row-start-3" 
        title={items[3].title}
        description={items[3].description} />
      <TextBlockWithBorder css="border-skin-orange row-start-5 md:row-start-3 md:col-start-5 lg:col-start-5 lg:row-start-3" 
        title={items[4].title}
        description={items[4].description} /> 
    </div>
  </div>
)

export default GridList
import * as React from "react"

const TextBlockWithIcon = ({icon, title, description, iconSize}) => {

  const renderShadowIcon = (size) => {
    if (size === "small") {
      return {boxShadow: "0px 0px 64px rgba(120, 113, 236, 0.3)"}
    } else {
      return {boxShadow: "0 0 #0000"}
    }
  }

  return (
    <div>
      <dt>
        <div className={`flex items-center justify-center ${iconSize === "small" ? "h-12 w-12 rounded-full bg-white text-skin-indigo" : "h-24 w-auto"}`} style={renderShadowIcon(iconSize)}>
          {icon}
        </div>
        <p className={`${iconSize === "medium" ? "text-center" : "text-left"} mt-5 uppercase text-skin-default font-bold text-xl`}>{title}</p>
      </dt>
      <dd className={`${iconSize === "medium" ? "text-center" : "text-left"} mt-2 text-skin-base text-lg`}>
        {description}
      </dd>
    </div>
  )
}

export default TextBlockWithIcon
import * as React from "react"
import Container from "../components/elements/container"
import { ContactButton } from "../components/elements/button"

const Hero = ({children, subhead, title, description, contactButton, illustration, backgroundColor}) => (
  <Container backgroundColor={backgroundColor} paddingDesktop="bottom">
    <div className="lg:grid lg:grid-cols-2 lg:gap-8">
      <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 text-center lg:px-0 lg:text-left lg:flex lg:items-center">
        <div className="lg:pt-24 lg:pr-5">
          <span className="text-skin-default text-lg font-semibold leading-5 uppercase tracking-wide">{subhead}</span>
          <h1 className="mt-4 space-y-1 sm:space-y-3 text-3xl font-extrabold text-transparent bg-gradient-to-r bg-clip-text from-skin-pink via-skin-red to-skin-orange sm:mt-5 sm:text-5xl lg:mt-6">
            <span className="block">{title.span_1}</span>
            <span className="sm:pb-3 block bg-clip-text text-transparent bg-gradient-to-r from-teal-200 to-cyan-400 md:pb-5">{title.span_2}</span>
          </h1>
          <p className="text-base text-skin-base sm:text-xl lg:text-lg xl:text-xl">
            {description}
          </p>
          <div className="my-10 sm:my-12 min-w-0 sm:min-w-full">
            <div className="flex flex-col sm:flex-row sm:justify-center lg:justify-start items-center space-y-8 md:space-x-6 md:space-y-0">
              {/* <ButtonDefault text="Inscription" size="large" path="/" />
              <ButtonSecondary text="Connextion" size="large" path="/" /> */}
              <ContactButton text={contactButton} size="large" />
            </div>
          </div>
        </div>
      </div>
      <div className="m-0 relative">
        <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 lg:max-w-none lg:px-0">
          <img className="w-full lg:absolute lg:inset-y-0 lg:left-0 lg:h-full lg:object-contain lg:max-w-none" src={illustration.src} alt={illustration.alt} />
        </div>
      </div>
    </div>
    {children}
  </Container>
)

export default Hero

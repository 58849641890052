import * as React from "react"
import Container from "../components/elements/container"
import Subheading from "../components/elements/subheading"

const Testimonial = ({title, testimonial_text, author, backgroundColor}) => (
  <Container backgroundColor={backgroundColor}>
    <div className="relative">
      <Subheading title={title} />
      <blockquote className="mt-10">
        <div className="max-w-3xl mx-auto text-center text-xl md:text-2xl lg:text-3xl leading-9 font-medium text-skin-base">     
          <div className="relative">
            <svg className="absolute top-0 left-0 transform -translate-x-3 -translate-y-6 md:-translate-y-3 h-6 w-6 text-skin-pink" viewBox="0 0 22 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
              <path d="M2.32681 14.6002C1.12356 13.3222 0.477539 11.8888 0.477539 9.56528C0.477539 5.47655 3.34782 1.81189 7.52183 0L8.56503 1.60979C4.66907 3.71723 3.90739 6.45201 3.60366 8.17628C4.23099 7.85152 5.05224 7.7382 5.85713 7.81297C7.96458 8.00806 9.62577 9.73817 9.62577 11.8888C9.62577 12.9732 9.19499 14.0132 8.42821 14.78C7.66142 15.5468 6.62144 15.9776 5.53704 15.9776C4.93735 15.9723 4.3447 15.8479 3.79357 15.6114C3.24244 15.3749 2.74384 15.0312 2.32681 14.6002ZM14.0089 14.6002C12.8056 13.3222 12.1596 11.8888 12.1596 9.56528C12.1596 5.47655 15.0299 1.81189 19.2039 0L20.2471 1.60979C16.3511 3.71723 15.5895 6.45201 15.2857 8.17628C15.9131 7.85152 16.7343 7.7382 17.5392 7.81297C19.6466 8.00806 21.3078 9.73817 21.3078 11.8888C21.3078 12.9732 20.8771 14.0132 20.1103 14.78C19.3435 15.5468 18.3035 15.9776 17.2191 15.9776C16.6194 15.9723 16.0268 15.8479 15.4756 15.6114C14.9245 15.3749 14.4259 15.0312 14.0089 14.6002Z"/>
            </svg>
            <p>
              {testimonial_text}
            </p>
            <svg className="absolute bottom-0 right-0 transform translate-x-3 translate-y-6 md:translate-y-3 h-6 w-6 text-skin-pink" viewBox="0 0 22 17" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
              <path d="M19.8343 2.35489C21.0376 3.63291 21.6836 5.0663 21.6836 7.38986C21.6836 11.4786 18.8133 15.1433 14.6393 16.9551L13.5961 15.3453C17.4921 13.2379 18.2537 10.5031 18.5575 8.77886C17.9301 9.10362 17.1089 9.21694 16.304 9.14217C14.1966 8.94708 12.5354 7.21697 12.5354 5.0663C12.5354 3.9819 12.9661 2.94192 13.7329 2.17513C14.4997 1.40835 15.5397 0.977574 16.6241 0.977574C17.2238 0.982816 17.8164 1.10729 18.3676 1.34374C18.9187 1.5802 19.4173 1.92392 19.8343 2.35489ZM8.15225 2.35489C9.35551 3.63291 10.0015 5.0663 10.0015 7.38986C10.0015 11.4786 7.13124 15.1433 2.95724 16.9551L1.91403 15.3453C5.81 13.2379 6.57167 10.5031 6.8754 8.77886C6.24808 9.10362 5.42683 9.21694 4.62193 9.14217C2.51449 8.94708 0.853298 7.21697 0.853298 5.0663C0.853298 3.9819 1.28407 2.94192 2.05086 2.17513C2.81764 1.40835 3.85763 0.977574 4.94202 0.977574C5.54171 0.982816 6.13437 1.10729 6.6855 1.34374C7.23663 1.5802 7.73522 1.92392 8.15225 2.35489Z"/>
            </svg>
          </div>
        </div>
        {/* Founder */}
        <div className="mt-20">
          <div className="flex flex-col items-center md:flex-row md:items-start md:justify-center">
            <div className="md:flex-shrink-0 overflow-hidden h-32 w-32 rounded-lg">
              {author.image}
            </div>
            <div className="mt-3 text-center md:mt-0 md:ml-10 md:flex md:flex-col md:items-start space-y-4">
              <div className="text-2xl font-bold text-skin-default">{author.name}</div>
              <div className="text-lg font-medium text-skin-base">{author.job}</div>
              <div className="flex justify-center">
                <a href={author.social_network[0].link} target="_blank" rel="noreferrer" className="text-gray-400 hover:text-gray-500">
                  <span className="sr-only">{author.social_network[0].name}</span>
                  {author.social_network[0].icon}
                </a>
              </div>
            </div>
          </div>
        </div>
      </blockquote>
    </div>
  </Container>
)

export default Testimonial



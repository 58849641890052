import * as React from "react"
import EarthIcon from "../components/svgs/icons/small/earth_icon"

export const conceptHero = {
  subhead:'Concept',
  title: {
    span_1: 'Pour que chacun ait',
    span_2: 'accès à un logement'
  },
  description: 'Stirrup s’engage pour transformer les logements vacants en tremplins solidaires pour les personnes ayant besoin d’un toit',
  contact_button: 'Contactez-nous',
  illustration: {
    src: 'https://res.cloudinary.com/djsymqq6e/image/upload/v1641639769/stirrup-front/concept/concept-hero_ph0wf6.jpg',
    alt: ''
  }
}

export const conceptValuePropositions = {
  title:'La mise en relation qui change la donne',
  icon: <img
    src="https://res.cloudinary.com/cryptr/image/upload/v1639325751/Stirrup/mission_icon_rnjhlm.jpg"
    alt="Arrows on a target"
  />,
  array: [
    {
      icon: <EarthIcon size="h-6 w-6" />,
      title: 'Propriétaires de logements vacants',
      description: 'Propriétaires privés, entreprises possédant du patrimoine immobilier, gestionnaires, professionnels de l’immobilier'
    },
    {
      icon: <EarthIcon size="h-6 w-6" />,
      title: 'Associations ou structure d’entraide',
      description: 'Associations qui viennent en aide à des personnes dans le besoin : suivi administratif, accompagnement social, aide à l’emploi ...'
    },
    {
      icon: <EarthIcon size="h-6 w-6" />,
      title: 'Bénéficiaires du logement tremplin',
      description: 'Personnes connaissant une rupture de parcours ou un accident de vie : incendie d’une maison, femmes victimes de violences, jeunes sortant de l’aide sociale à l’enfance, réfugiés, ... '
    }
  ]
}

export const conceptHowItWorks = {
  title:'Mise en relation facilitée et sécurisée',
  description: 'Stirrup vous apporte l’outil pour contractualiser, suivre et gérer les prêts de logements tremplins',
  contact_button: 'Contactez-nous',
  steps: [
    {
      number: '1',
      description: 'Vérification de la fiabilité des parties prenantes'
    },
    {
      number: '2',
      description: 'Génération automatique du contrat de prêt'
    },
    {
      number: '3',
      description: 'Signature électronique des contrats d\'assurance'
    },
    {
      number: '4',
      description: 'Suivi mensuel et digitalisé du logement'
    },
  ]
}


export const conceptGoals = {
  title:'Notre Mission',
  array: [
    {
      title: 'Accès au logement',
      description: 'Chez Stirrup, nous sommes convaincus que l’accès au logement digne est un droit fondamental. Nous nous engageons pour que plus personne ne soit à la rue',
      icon: <img
        src="https://res.cloudinary.com/cryptr/image/upload/v1639325751/Stirrup/mission_icon_rnjhlm.jpg"
        alt="Arrows on a target"
      />
    },
    {
      title: 'Réinsertion',
      description: 'L’accès au logement digne est le premier pas vers le retour à une vie plus sereine. Lorsque nos bénéficiaires ont un toit, ils sont plus à même de (re)trouver un emploi et de réinsérer dans une vie sociale.',
      icon: <img
        src="https://res.cloudinary.com/cryptr/image/upload/v1639325751/Stirrup/mission_icon_rnjhlm.jpg"
        alt="Arrows on a target"
      />
    },
    {
      title: 'Impact',
      description: 'Nos associations partenaires accompagnent les bénéficiaires des logements tremplins vers l’accès à un logement à longue durée afin de pérenniser leur situation.',
      icon: <img
        src="https://res.cloudinary.com/cryptr/image/upload/v1639325751/Stirrup/mission_icon_rnjhlm.jpg"
        alt="Arrows on a target"
      />
    },
  ]
}

export const conceptTestimonial = {
  title:'Nos valeurs',
  testimonial_text: 'Je rêve qu’un jeune qui sorte de foyer ne se retrouve pas à la rue, que les familles de réfugiés ne soient ballottées pendant des mois dans des hôtels, et que chaque personne en précarité ait une chance d’avoir une vie plus confortable',
  author : {
    image: <img
      src="https://res.cloudinary.com/cryptr/image/upload/v1637610329/Stirrup/stirrup_delphine_barthe_ceo_esfq5m.jpg"
      alt="Delphine Barthe - Stirrup CEO"
    />,
    name: 'Delphine Barthe',
    job: 'CEO & Super Woman',
    social_network: [
      {
        name: 'Linkedin',
        icon: 
        <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" aria-hidden="true">
          <path fillRule="evenodd" d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z" clipRule="evenodd" />
        </svg>, 
        link: 'https://www.linkedin.com/in/delphinebarthe/'
      }
    ]
  }
}

export const conceptGridList = {
  title: null,
  items: [
    {
      title: 'Entraide',
      description: 'La raison d’être de Stirrup est l’entraide, la solidarité envers ceux qui en ont le plus besoin'
    },
    {
      title: 'Simplicité',
      description: 'Notre outil facilite en encadre la relation de prêt de logement afin que chaque partie prenante se sente en confiance'
    },
    {
      title: 'Gagnant-Gagnant',
      description: 'Nous avons à coeur de construire un cercle vertueux ou chaque acteur est gagnant'
    },
    {
      title: 'Engagement',
      description: 'L’équipe Stirrup ainsi que tous nos partenaires sont pleinement engagés au service de la lutte contre le sans-abrisme'
    },
    {
      title: 'Innovation',
      description: 'En tant que startup à impact, nous mettons les outils digitaux au service du bien commun'
    },
  ]
}

export const conceptFooter = {
  title: {
    span_1: 'Notre engagement vous parle',
    span_2: 'Vous souhaitez prendre part à la mission de Stirrup ?'
  },
  contact_button: 'Contactez nous'
}
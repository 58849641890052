import * as React from "react"
import Container from "../components/elements/container"
import H2 from "../components/elements/h2_title"

const Impact = () => (
  <Container backgroundColor="bg-gradient-to-r from-skin-pink-light to-skin-orange-light">
    <H2 title="Notre impact" colored/>
    <div className="flex flex-col items-center text-skin-indigo">
      <img
        src="https://res.cloudinary.com/djsymqq6e/image/upload/v1641640244/stirrup-front/concept/concept-impact_vubqjh.jpg"
        alt="Window"
      />
      <div className="pt-6 sm:pt-16 lg:pt-24 flex flex-col items-center">
        <div className="md:max-w-screen-sm lg:max-w-screen-md 2xl:max-w-screen-lg flex flex-col items-center px-8 md:px-0">
          <h2 className="text-center leading-tight text-4xl sm:text-5xl">
            Plus de <span className="font-bold">45,000</span> personnes ont pu être logées grâce à Stirrup
          </h2>
        </div>
      </div>
    </div>
  </Container>
)

export default Impact



import * as React from "react"
import Container from "../components/elements/container"
import { ContactButton } from "../components/elements/button"

const HowItWorks = ({conceptHowItWorks}) => (
  <Container backgroundColor="bg-skin-warm">
    <div className="relative space-y-12 lg:space-y-0 lg:grid lg:grid-cols-2 lg:gap-8">
      <div className="relative lg:pr-24">
        <h2 className="text-2xl font-extrabold tracking-tight sm:text-3xl text-transparent bg-gradient-to-r bg-clip-text from-skin-pink via-skin-red to-skin-orange">
          {conceptHowItWorks.title}
        </h2>
        <p className="mt-3 text-lg text-skin-base">
          {conceptHowItWorks.description}  
        </p>
        <ContactButton text={conceptHowItWorks.contact_button} size="regular" />
      </div>

      <div className="lg:-mx-4 space-y-6 relative lg:mt-0" aria-hidden="true">
        {conceptHowItWorks.steps.map((step, index) => {
          return (
            <div key={index} className="flex flex-row items-center text-skin-default">
              <h3 className="w-8 text-center text-5xl font-extrabold">
                {step.number}
              </h3>
              <p className="ml-10 text-lg">{step.description}</p>
            </div>
          )
        })}               
      </div>
    </div>
  </Container>
)

export default HowItWorks
import * as React from "react"

const Container = ({ children, backgroundColor, paddingDesktop }) => {

  const renderSectionStyle = (padding) => {
    switch(padding) {
      case 'bottom':
        return `py-10 lg:pt-0 lg:pb-32 ${backgroundColor}`
      case 'top':
        return `py-10 lg:pb-0 lg:pt-32 ${backgroundColor}`
      default:
        return `py-10 lg:py-32 ${backgroundColor}`
    }
  }
  
  return (
    <section className={renderSectionStyle(paddingDesktop)}>
      <div className="max-w-7xl mx-auto px-4 sm:px-6">
        {children}
      </div>
    </section>
  )
}

export default Container

import * as React from "react"
import Container from "../elements/container"
import TextBlockWithIcon  from "../elements/text_block_with_icon"
import H2 from "../elements/h2_title"
import Subheading from "../elements/subheading"

const SectionThreeColumn = ({data, sectionType, iconSize, paddingDesktop, backgroundColor}) => {
  return (
    <Container backgroundColor={backgroundColor} paddingDesktop={paddingDesktop}>
      <>
        {sectionType === "h2" ? (
          <H2
            icon={data.icon} 
            title={data.title}
          />
        ) : (
          <Subheading title={data.title} />
        )}
        <dl className="space-y-10 lg:space-y-0 lg:grid lg:grid-cols-3 lg:gap-16">
          {
            data.array.map((item, index) => {
              return (
                <TextBlockWithIcon 
                  key={index}
                  icon={item.icon}
                  title={item.title}
                  description={item.description}
                  iconSize={iconSize}
                />
              )
            })
          }
        </dl>
      </>
    </Container>
  )
}

export default SectionThreeColumn

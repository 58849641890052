import * as React from "react"
import Layout from "../components/layout"
import Hero from "../components/hero"
import Impact from "../components/impact"
import HowItWorks from "../components/how_it_works"
import { 
  conceptHero, 
  conceptValuePropositions, 
  conceptHowItWorks, 
  conceptGoals, 
  conceptTestimonial,
  conceptGridList,
  conceptFooter
} from "../data/concept"
import SectionThreeColumn from "../components/sections/section_three_column"
import Testimonial from "../components/testimonial"
import GridList from "../components/grid_list"
import Container from "../components/elements/container"

const ConceptPage = () => {
  return (
    <Layout dataFooter={conceptFooter}>
      <Hero 
        subhead={conceptHero.subhead}
        title={conceptHero.title}
        description={conceptHero.description}
        contactButton={conceptHero.contact_button}
        illustration={conceptHero.illustration}
        backgroundColor="bg-white"
      />
      <SectionThreeColumn
        paddingDesktop="bottom"
        backgroundColor="bg-white"
        sectionType="h2"
        iconSize="small"
        data={conceptValuePropositions}
      />
      <Impact />
      <HowItWorks conceptHowItWorks={conceptHowItWorks} />
      <SectionThreeColumn
        paddingDesktop="top-and-bottom"
        backgroundColor="bg-white"
        sectionType="h3"
        iconSize="medium"
        data={conceptGoals}
      />
      <Testimonial 
        title={conceptTestimonial.title}
        testimonial_text={conceptTestimonial.testimonial_text}
        author={conceptTestimonial.author}
        backgroundColor="bg-skin-cold"
      />
      <Container backgroundColor="bg-skin-cold" >
        <GridList
          title={conceptGridList.title}
          items={conceptGridList.items} 
        />
      </Container>
    </Layout>
  )
}

export default ConceptPage